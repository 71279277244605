// import React from 'react';
// import {FuseNavigation} from '@fuse';
// import clsx from 'clsx';
// import {useSelector} from 'react-redux';
// import { DepartmentMenu, SuperAdmin} from './MenuData';
// import { el } from 'date-fns/locale';
// import { All } from './MenuData';
// import history from '@history';



// function Navigation(props)


// {
//     var navigation=null;
//     var roleName=sessionStorage.getItem("roleId");
// //  alert("out side"+JSON.stringify(roleName))
//     if(roleName==2){
//         // alert("-----dep0")
//      navigation = DepartmentMenu;
//     }
//     else if(roleName==1){
//         // alert("---all1")
//         navigation = All;
//     }
//     else if(roleName==0){
//         // alert(JSON.stringify(SuperAdmin));
//         navigation = SuperAdmin;
//     }
//     // alert(navigation);

//     return (
//         <div>
//         {
//             (navigation==null)?history.push('/pages/Home'): <FuseNavigation className={clsx("navigation", props.className)} navigation={navigation} layout={props.layout} dense={props.dense}/>
//         }


//     </div>

//        // <FuseNavigation className={clsx("navigation", props.className)} navigation={navigation} layout={props.layout} dense={props.dense}/>
//     );
// }

// Navigation.defaultProps = {
//     layout: "vertical"
// };

// export default Navigation;
import React, { useState } from 'react';
import { FuseNavigation } from '@fuse';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { DepartmentMenu, SuperAdmin } from './MenuData';
import { Button, Icon, Typography } from '@material-ui/core';
import { All } from './MenuData';
import { Doctor } from './MenuData';
import LogoutPopup from '@fuse/components/Logoutpopup/logoutpopup';
import history from '@history';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function Navigation(props) {
  const [logoutPopup, setLogoutPopup] = useState(false);

  const handleLogoutConfirm = () => {
    // Add your logout logic here
    console.log('Logging out...');
    setLogoutPopup(false);
  };

  const handleLogoutCancel = () => {
    setLogoutPopup(false);
  };

  var navigation = null;
  var roleName = sessionStorage.getItem("roleId");

  if (roleName == 2) {
    navigation = DepartmentMenu;
  } else if (roleName == 1) {
    navigation = All;
  } else if (roleName == 4) {
    navigation = SuperAdmin;
  }

  else if (roleName == 5) {
    navigation = Doctor;
  }

  return (
    <div>
      {
        (navigation == null) ?
          history.push('/pages/Home') :
          <>
            <FuseNavigation className={clsx("navigation", props.className)} navigation={navigation} layout={props.layout} dense={props.dense} />
            <Button
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '15px',
                marginLeft: '15px',
                textTransform: 'none',
                fontWeight: 'normal',
                marginTop:'-5px'  
              }}
              color="#ffff"
              onClick={() => setLogoutPopup(true)}
            >
              Logout
            </Button>
            <LogoutPopup
              isOpen={logoutPopup}
              onClose={() => setLogoutPopup(false)}
              onConfirm={handleLogoutConfirm}
            />
          </>
      }
    </div>
  );
}


Navigation.defaultProps = {

  layout: "vertical"

};

export default Navigation;


