import '@fake-db'; // Assuming this is for mock data or testing purposes
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { FuseAuthorization, FuseLayout, FuseTheme } from '@fuse';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { StylesProvider } from '@material-ui/styles';
import {create} from 'jss';
import jssPreset from '@material-ui/styles/jssPreset';
import jssExtend from 'jss-extend';
import history from '@history';
import firebase from 'firebase/compat/app'; // Import Firebase compat app module
import 'firebase/compat/auth'; // Import Firebase compat authentication module
import { Auth } from './auth'; // Assuming Auth component manages Firebase auth context or hooks
import store from './store';
import AppContext from './AppContext';
import routes from './fuse-configs/routesConfig';


const TRACKING_ID = "G-E74T1WRQ13";
ReactGA.initialize(TRACKING_ID);

const firebaseConfig = {
    apiKey: "AIzaSyAQuuv_gv04gfFhATAzPOzHHigmNWcuPY0",
    authDomain: "test-ravindra.firebaseapp.com",
    projectId: "test-ravindra",
    storageBucket: "test-ravindra.appspot.com",
    messagingSenderId: "1078634589907",
    appId: "1:1078634589907:web:e6126f45518c7439106e26",
    measurementId: "G-1SY3Y220C5"
  
};

// Initialize Firebase app if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Create JSS instance with Material-UI preset and plugins
const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = () => {
  return `${Math.floor(Math.random() * 1000000)}`;
};

const App = () => {
  useEffect(() => {
    // Set up Firebase authentication state observer
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in, you can handle authenticated state here
        console.log('User is signed in:', user);
      } else {
        // User is signed out, handle sign-out state or redirect if needed
        console.log('User is signed out');
      }
    });

    return () => {
      // Unsubscribe from the auth observer on component unmount
      unsubscribe();
    };
  }, []);

  return (
    <AppContext.Provider value={{ routes }}>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <Auth>
            <Router history={history}>
              <FuseAuthorization>
                <FuseTheme>
                  <FuseLayout />
                
                </FuseTheme>
                
              </FuseAuthorization>
            </Router>
          </Auth>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  );
};

export default App;

